<template>
  <div>
    <div class="alert alert-info">
      <strong><i class="fa fa-flask mr-1"></i>EXPERIMENTAL</strong> This feature was recently added and may have limited functionality. Report any
      issues or feedback using the question mark at the bottom right.
    </div>
    <!-- <div v-if="$auth.isAdmin">
      <div class="row">
        <div class="col-md-6">
          <h5 class="mb1">Form</h5>
          <pre class="bg-lightest p-3">{{ form }}</pre>
        </div>
        <div class="col-md-6">
          <h5 class="mb1">Template</h5>
          <pre class="bg-lightest p-3">{{
            {
              data: {
                ...templateForm,
                data: {
                  startDate: form.startDate,
                  endDate: form.endDate
                },
                type: isDynamic ? 'dynamic' : template.type,
                subType: isDynamic ? form.subType : template.subType,
                source: isDynamic ? form.source : template.source,
                fileFormat: form.fileFormat,
                singleSource: !!template?.singleSource,
                strictSource: !!template?.strictSource,
                beta: false,
                disabled: false
              }
            }
          }}</pre>
        </div>
      </div>
    </div> -->
    <div class="row">
      <div class="col-lg-4">
        <div class="bg-light rounded-md p-4">
          <div class="border-bottom mb-4 pb-1">
            <h5 class="mb-1">1. Data Selection</h5>
            <p class="font-size-sm mb-0">Narrow down your report to the below sets of data.</p>
          </div>
          <FormGroup
            v-if="!template.singleSource"
            id="portfolios"
            :options="assetGroupOptions"
            description="Select a portfolio to automatically select all assets within it"
            type="select2"
            :config="{ allowClear: true }"
            label="Portfolios"
            placeholder="Select a portfolio"
            @input="onSelectPortfolio"
          />

          <SpinnerLogo v-if="tree.loading" />
          <div v-else>
            <FormItem id="tree-search" v-model="tree.search.query" name="tree-search" class="mb-2" placeholder="Search..." @input="onSearch" />

            <Spinner v-if="tree.search.loading" class="mt-5" size="sm" />
            <div v-else class="Tree">
              <div v-for="item in treeItems" :key="item.value" class="Tree-item">
                <div
                  class="d-flex justify-content-between bg-white my-2 rounded-sm font-w500"
                  :class="{
                    selected: form.sourceIds.some(s => s._id === item.value),
                    'selected--partial':
                      form.sourceIds.some(s => s.parentId === item.value) && !item.children.every(c => form.sourceIds.some(s => s._id === c.value))
                  }"
                >
                  <div class="d-flex flex-grow-1 align-items-center">
                    <div v-if="!hideAssetExpand" class="Tree-expander pl-2 py-2" @click="onClickTreeItem(item)">
                      <i class="fa fa-fw mr-2" :class="item.expanded ? 'fa-minus' : 'fa-plus'"></i>
                    </div>
                    <div class="Tree-label flex-grow-1 pl-1 pr-2 py-2" :class="{ 'pl-2': hideAssetExpand }" @click="onSelectTreeItem(item)">
                      <div><i class="fa fa-building text-primary fa-fw fa-sm mr-1"></i>{{ item.label }}</div>
                      <div>
                        {{ item.subLabel }}
                      </div>
                    </div>
                    <div v-if="item.loading" class="pr-2 py-2">
                      <i class="fa fa-spin fa-circle-notch" />
                    </div>
                  </div>
                </div>
                <div v-if="item.expanded">
                  <div v-for="childItem in item.children" :key="childItem.value" class="Tree-item my-2 ml-4">
                    <div
                      class="bg-white Tree-label d-flex align-items-center rounded-sm font-size-sm p-2"
                      :class="{ selected: form.sourceIds.some(s => s._id === item.value) || form.sourceIds.some(s => s._id === childItem.value) }"
                      @click="onSelectTreeItem(childItem)"
                    >
                      <div>
                        <UtilityTypeIcon :type="childItem.utilityType" :text="false" icon-class="fa text-primary" />
                      </div>
                      <div>
                        <div class="font-w600">
                          {{ childItem.label }}
                        </div>
                        <span>{{ childItem.subLabel }}</span>
                      </div>
                    </div>
                    <div v-for="grandChildItem in childItem.children" :key="grandChildItem.value" class="Tree-item my-2 ml-4">
                      <div
                        class="bg-white Tree-label d-flex align-items-center rounded-sm font-size-sm p-2"
                        :class="{
                          selected: form.sourceIds.some(s => s._id === item.value) || form.sourceIds.some(s => s._id === grandChildItem.value)
                        }"
                        @click="onSelectTreeItem(grandChildItem)"
                      >
                        <div>
                          <UtilityTypeIcon :type="grandChildItem.utilityType" :text="false" icon-class="fa text-primary" />
                        </div>
                        <div>
                          <div class="font-w600">
                            {{ grandChildItem.label }}
                          </div>
                          <span>{{ grandChildItem.subLabel }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="!item.loading && item.children.length === 0" class="alert alert-warning py-1 px-2 mb-0 ml-4">
                    <i class="fa fa-exclamation-circle fa-fw mr-2"></i>No accounts found
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-7">
        <div v-if="!isDynamic && template">
          <SectionTitle>Generating report: {{ template.name }}</SectionTitle>
          <p class="mb-3">{{ template.description }}</p>
        </div>
        <div v-else-if="isDynamic">
          <SectionTitle>Dynamic Report</SectionTitle>
          <p class="mb-3">
            Build your own report with dates, headings and data types of your choice. If you plan to re-use your report in the future, you can save it
            as a template.
          </p>
        </div>

        <div class="bg-light rounded-md p-4 mb-3">
          <div class="border-bottom mb-4 pb-1">
            <h5 class="mb-1">Selected Data</h5>
            <p class="font-size-sm mb-3">The report will be run for the below assets &amp; meters:</p>
          </div>

          <div v-if="selectedSources.assets.length > 0">
            <div v-if="selectedAssetGroup" class="bg-white p-2 rounded-sm mb-2 d-flex selected--portfolio">
              <div class="pr-2 pt-1"><i class="fa fa-buildings fa-fw text-primary" /></div>
              <div>
                <div class="font-w600">{{ selectedAssetGroup.name }}</div>
                <div class="font-size-sm">All Assets & Accounts</div>
              </div>
            </div>
            <div
              v-for="source in selectedSources.assets"
              v-else
              :key="source._id"
              class="bg-white p-2 rounded-sm mb-2 d-flex"
              :class="{
                'selected--partial': selectedSources.accounts.filter(s => s.assetId === source.value),
                selected: source.isSelected
              }"
            >
              <div class="pr-2"><i class="fa fa-building fa-fw text-primary" /></div>
              <div>
                <div class="font-w600">{{ source.label }}</div>
                <div v-if="source.isSelected && !template.strictSource" class="font-size-sm">All Accounts</div>
                <div v-else>
                  <div v-for="subSource in selectedSources.accounts.filter(s => s.assetId === source.value)" :key="subSource.value">
                    <div class="my-1 font-size-sm">
                      <UtilityTypeIcon :type="subSource.type" :text="false" icon-class="fa text-primary" />{{ subSource.label }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="alert alert-warning mb-0"><i class="fa fa-exclamation-circle fa-fw mr-2"></i>No data selected</div>
          </div>
        </div>

        <div v-if="isDynamic" class="bg-light rounded-md p-4 mb-3">
          <div class="border-bottom mb-4 pb-1">
            <h5 class="mb-1">2. Report Type</h5>
            <p class="font-size-sm mb-0">Select the type of data to show on each row.</p>
          </div>
          <div class="row">
            <div class="col-xl-6">
              <FormGroup
                id="subType"
                v-model="form.subType"
                label=""
                class="mb-0"
                type="select"
                :options="subTypeOptions"
                @input="() => onSelectType()"
              />
            </div>
          </div>
        </div>

        <div class="bg-light rounded-md p-4 mb-3">
          <div class="border-bottom mb-4 pb-1">
            <h5 class="mb-1">{{ isDynamic ? 3 : 2 }}. Settings</h5>
          </div>

          <div v-if="displayCheck.dates" class="row mb-3 border-bottom pb-4">
            <div class="col-xl-6 col-lg-6">
              <FormGroup
                id="dateRange"
                v-model="dateRange"
                class="mb-0"
                label="Date Range"
                type="dateRangePicker"
                :config="{ utc: true, time: true }"
                @input="onSelectDateRange"
              />
            </div>
          </div>

          <div class="row">
            <div v-if="displayCheck.granularity" class="col-xl-3">
              <FormGroup
                id="granularity"
                :key="template._id"
                v-model="form.granularity"
                :disabled="form.subType === 'dataQuality'"
                class="mb-0"
                label="Granularity"
                type="select"
                :options="granularityOptions"
              />
            </div>

            <div v-if="displayCheck.consumptionSource" class="col-xl-4">
              <FormGroup
                id="consumptionSource"
                v-model="form.consumptionSource"
                class="mb-0"
                label="Consumption Data Source"
                type="select"
                :options="consumptionSourceOptions"
              />
            </div>

            <div v-if="displayCheck.rawUnits" class="col-xl-4">
              <FormGroup id="rawUnits" v-model="form.rawUnits" class="mb-0" label="Units" type="select" :options="rawUnitOptions" />
            </div>

            <div v-if="displayCheck.fileFormat" class="col-xl-3">
              <FormGroup
                id="fileFormat"
                :key="template._id"
                v-model="form.fileFormat"
                class="mb-0"
                label="Format"
                type="select"
                placeholder="Select a file format..."
                :options="fileFormatOptions"
              />
            </div>
          </div>

          <div v-if="displayCheck.dateField || displayCheck.showSimulated" class="row mt-3 border-top pt-4">
            <div v-if="displayCheck.showSimulated" class="col-xl-3">
              <FormGroup
                id="showSimulated"
                v-model="form.showSimulated"
                class="mb-0"
                label="Show Simulated Data"
                type="radio"
                :options="[
                  { label: 'Yes', value: true },
                  { label: 'No', value: false }
                ]"
              />
            </div>

            <div v-if="displayCheck.dateField" class="col-xl-4">
              <FormGroup
                id="dateField"
                v-model="form.dateField"
                class="mb-0"
                label="Date Field"
                description="The invoice field to apply the above date range too"
                type="select"
                :options="invoiceDateFieldOptions"
              />
            </div>
          </div>
        </div>

        <div v-if="displayCheck.chartSettings && form.fileFormat === 'chart'" class="bg-light rounded-md p-4 mb-3">
          <div class="border-bottom mb-4 pb-1">
            <h5 class="mb-1">{{ isDynamic ? 4 : 3 }}. Chart Settings</h5>
          </div>

          <div v-for="option in chartGenerationOptions" :key="option.name">
            <div v-if="option.type === 'buttons'" class="d-flex align-items-center mr-3 pr-3">
              <a
                v-for="item in option.items"
                :key="item.value"
                href="#"
                class="font-w600 px-3 py-1"
                :class="{ 'bg-primary text-white border rounded-md': form.chartOptions[option.name] === item.value }"
                @click.prevent="form.chartOptions[option.name] = item.value"
                ><i :class="`fa-solid ${item.icon} mr-1`"></i> {{ item.label }}</a
              >
            </div>
            <div v-else>
              <FormGroup
                :id="option.name"
                v-model="form.chartOptions[option.name]"
                class="mb-0"
                :label="option.label"
                :type="option.type"
                :options="option.items"
              />
            </div>
          </div>
        </div>

        <div v-if="isDynamic" class="bg-light rounded-md p-4 mb-3">
          <h5>{{ isDynamic ? 4 : 3 }}. Headers/Fields</h5>
          <SpinnerLogo v-if="schemaLoading" />
          <FormGroup v-else id="source" v-model="form.schema" class="mb-0" label="" :options="schemaItems" type="multi-check-options" alt />
        </div>

        <div v-if="displayCheck" :key="form.subType" class="bg-light rounded-md p-4 mb-3">
          <div class="border-bottom mb-4 pb-1">
            <h5 class="mb-1">Filters</h5>
          </div>

          <div class="row mb-3">
            <div v-if="displayCheck.accountTags" class="col-xl-3">
              <FormGroup
                id="accountTags"
                v-model="form.accountTag"
                placeholder="Select a tag..."
                label="Account Tag"
                type="select"
                description="Filter accounts by a tag"
                :options="accountTags.map(tag => ({ label: tag, value: tag }))"
              />
            </div>
            <div v-if="displayCheck.utilityType" class="col-xl-3">
              <FormGroup
                id="utilityType"
                v-model="form.utilityType"
                class="mb-3"
                label="Utility Type"
                placeholder="All"
                type="select2"
                :config="{ allowClear: true }"
                :options="utilityTypeOptions"
              />
            </div>
            <div v-if="displayCheck.includeSubMeters" class="col-xl-3">
              <FormGroup
                id="includeSubMeters"
                v-model="form.includeSubMeters"
                class="mb-0"
                label="Include Sub Meters"
                type="select"
                :options="[
                  { label: 'Yes', value: true },
                  { label: 'No', value: false }
                ]"
              />
            </div>
            <div v-if="displayCheck.readingSource" class="col-xl-3">
              <FormGroup
                id="readingSource"
                v-model="form.readingSource"
                class="mb-0"
                label="Source"
                placeholder="All"
                type="select2"
                :config="{ allowClear: true }"
                :options="[
                  { label: 'Manual', value: 'manual' },
                  { label: 'Invoice', value: 'invoice' },
                  { label: 'Import', value: 'import' }
                ]"
              />
            </div>
          </div>

          <div class="row mb-3">
            <div class="col">
              <button v-if="!generating" class="btn btn-primary btn-block" :disabled="!isAllowedGenerate" @click="onClickGenerate">
                <i class="fa fa-gear mr-1"></i> Generate
              </button>
              <button v-else class="btn btn-primary btn-block" :disabled="!isAllowedGenerate">
                <i class="fa fa-gear mr-1 fa-spin"></i> Generating...
              </button>
            </div>
            <div class="col-xl-4">
              <button class="btn btn-warning btn-block" :disabled="!isAllowedGenerate" @click="templateModal = true">
                <i class="fa fa-save mr-1"></i> Save as template
              </button>
            </div>
          </div>
        </div>

        <ConfirmModal
          :loading="templateLoadingAction.create"
          :open="!!templateModal"
          title="Save As Template"
          @close="templateModal = false"
          @submit="onCreateTemplate"
        >
          <ReportTemplateForm v-model="templateForm" />
        </ConfirmModal>
      </div>
    </div>
  </div>
</template>
<script>
import { debounce } from 'lodash';
import moment from 'moment';
import {
  sourceOptions,
  fileFormatOptions,
  frequencyOptions,
  invoiceDateFieldOptions,
  rawUnitOptions,
  consumptionSourceOptions,
  chartGenerationOptions
} from '../options';
import { mapActions, mapGetters } from 'vuex';

import ConfirmModal from '@/components/ConfirmModal';
import FormGroup from '@/components/FormGroup';
import FormItem from '@/components/FormItem';
import ReportTemplateForm from '@/components/forms/ReportTemplateForm';
import SectionTitle from '@/components/base/SectionTitle';
import Spinner from '@/components/Spinner';
import SpinnerLogo from '@/components/SpinnerLogo';
import UtilityTypeIcon from '@/components/UtilityTypeIcon';

const accountSelectParams = 'name,meterPointNumber,type,deviceId,assetId,thirdParties,parentAccountId';

export default {
  name: 'ReportGenerate',
  components: {
    ConfirmModal,
    FormGroup,
    FormItem,
    ReportTemplateForm,
    SectionTitle,
    Spinner,
    SpinnerLogo,
    UtilityTypeIcon
  },
  data() {
    return {
      templateModal: false,
      templateForm: {
        name: '',
        description: '',
        category: '',
        access: 'user'
      },
      dateRange: [moment.utc().subtract(1, 'month').startOf('month').toDate(), moment.utc().subtract(1, 'month').endOf('month').toDate()],
      form: {
        startDate: null,
        endDate: null,
        granularity: 'monthly',
        fileFormat: 'xlsx',
        source: 'asset',
        type: 'dynamic',
        subType: 'assets',
        filters: {},
        rawUnits: false,
        consumptionSource: 'combined',
        sourceIds: [],
        dateField: null,
        dayNightSplit: false,
        schema: [],
        includeSubMeters: true,
        showSimulated: false,
        chartOptions: {},
        utilityType: null,
        readingSource: null
      },
      sourceOptions,
      frequencyOptions,
      invoiceDateFieldOptions,
      rawUnitOptions,
      consumptionSourceOptions,
      chartGenerationOptions,
      selectedAssetGroup: null,
      assetGroupOptions: [],
      generating: false,
      sourcesLoading: false,
      sourceItems: [],
      schemaLoading: false,
      schemaItems: [],
      template: {
        data: {}
      },
      tree: {
        expanded: [],
        children: {},
        loading: true,
        childrenLoading: [],
        search: {
          loading: false,
          query: ''
        }
      },
      filteredAssets: [],
      debouncedSearchFunction: null
    };
  },
  computed: {
    ...mapGetters({
      accounts: 'account/accounts',
      assets: 'asset/assets',
      assetGroups: 'assetGroup/assetGroups',
      granularities: 'util/granularities',
      assetSchema: 'asset/schema',
      accountSchema: 'account/schema',
      assetGroupSchema: 'assetGroup/schema',
      readingSchema: 'reading/schema',
      consumptionSchema: 'consumption/schema',
      invoiceSchema: 'invoice/schema',
      accountSearchResults: 'account/searchResults',
      supplierSchema: 'supplier/schema',
      templateLoadingAction: 'reportTemplate/loadingAction',
      templatesResults: 'reportTemplate/reportTemplates',
      systemTemplatesResults: 'reportTemplate/systemTemplates',
      utilityTypes: 'util/utilityTypes',
      accountTags: 'account/tags'
    }),
    utilityTypeOptions() {
      const items = [...this.utilityTypes.map(i => ({ label: i.name, value: i.value }))];
      items.sort((a, b) => a.label.localeCompare(b.label));
      return items;
    },
    hideAssetExpand() {
      // strictSource means if you selected an asset, the accounts below are not selected/required
      if (this.template.strictSource && this.template.source === 'asset') {
        return true;
      }

      return false;
    },
    singleAssetOnly() {
      return this.template.singleSource && this.template.source === 'asset';
    },
    formDisplayRules() {
      return {
        fileFormat: {
          type: ['dynamic', 'spreadsheet', 'chart', 'table', 'pdf']
        },
        dates: {
          subType: [
            'readings',
            'invoices',
            'consumptions',
            'combinedConsumption',
            'emission',
            'hh',
            'dayNight',
            'dataQuality',
            'invoiceValidation',
            'meterManagement',
            'assetDetailed',
            'assetSummary',
            'dwellant'
          ]
        },
        rawUnits: {
          subType: ['readings', 'hh', 'consumptions']
        },
        consumptionSource: {
          subType: ['hh', 'combinedConsumption', 'emission', 'dayNight', 'dataQuality']
        },
        dateField: {
          subType: ['invoices', 'dwellant']
        },
        granularity: {
          subType: ['combinedConsumption', 'emission', 'dataQuality']
        },
        showSimulated: {
          subType: ['combinedConsumption', 'emission']
        },
        chartSettings: {
          subType: ['combinedConsumption']
        },
        utilityType: {
          source: ['asset', 'assets']
        },
        readingSource: {
          subType: ['readings']
        },
        includeSubMeters: {
          subType: ['accounts', 'invoices', 'readings', 'consumptions', 'dwellant'],
          source: ['account', 'accounts', 'accountsByRegister']
        },
        accountTags: {
          subType: ['accounts', 'invoices', 'readings', 'consumptions'],
          source: ['account', 'accounts']
        }
      };
    },
    granularityOptions() {
      return this.granularities.filter(gran => gran.value !== 'halfHourly' && gran.value !== 'hourly').map(g => ({ label: g.name, value: g.value }));
    },
    fileFormatOptions() {
      if (this.template._id && this.template.type === 'pdf') {
        return fileFormatOptions.filter(f => f.value === 'pdf');
      }

      return fileFormatOptions.filter(f => f.value !== 'pdf');
    },
    isAllowedGenerate() {
      return this.form.sourceIds.length > 0 && ((this.isDynamic && this.form.schema.length > 0) || !this.isDynamic) && !this.generating;
    },
    isDynamic() {
      return this.$route.name === 'reports-dynamic';
    },
    isTemplate() {
      return this.template?._id;
    },
    isSingleSource() {
      return this.isPDF && !this.template.data.source.endsWith('s');
    },
    displayCheck() {
      return Object.entries(this.formDisplayRules).reduce((acc, [key, value]) => {
        if (value === true) {
          acc[key] = true;
          return acc;
        }

        Object.entries(value).forEach(([k, v]) => {
          if (v.includes(this.form[k])) {
            acc[key] = true;
          }
        });

        return acc;
      }, {});
    },
    subTypeOptions() {
      return [
        { label: 'Assets', value: 'assets' },
        { label: 'Accounts', value: 'accounts' },
        { label: 'Portfolios', value: 'assetGroups' },
        { label: 'Readings', value: 'readings' },
        { label: 'Non-Cumulative Data', value: 'consumptions' },
        { label: 'Invoices', value: 'invoices' }
      ];
    },
    treeItems() {
      const assets = [...this.filteredAssets].sort((a, b) => a.siteName.localeCompare(b.siteName));

      return assets.map(asset => {
        const children = this.tree.children[asset._id] || [];

        return {
          label: asset.siteName,
          value: asset._id,
          type: 'asset',
          expanded: this.tree.expanded.includes(asset._id),
          loading: this.tree.childrenLoading.includes(asset._id),
          children: children
            .filter(
              subAcc =>
                (this.tree.search.query && subAcc.search) || (!this.tree.search.query && !subAcc.parentAccountId && subAcc.assetId === asset._id)
            )
            .map(a => ({
              label: a.name,
              subLabel: a.meterPointNumber,
              value: a._id,
              assetId: a.assetId,
              type: 'account',
              utilityType: a.type,
              children: children
                .filter(
                  subAcc =>
                    (this.tree.search.query && subAcc.search && subAcc.parentAccountId === a._id) ||
                    (!this.tree.search.query && subAcc.parentAccountId === a._id)
                )
                .map(subAcc => ({
                  label: subAcc.name,
                  subLabel: subAcc.meterPointNumber,
                  value: subAcc._id,
                  assetId: a.assetId,
                  type: 'account',
                  utilityType: subAcc.type
                }))
            }))
        };
      });
    },
    selectedSources() {
      const accounts = Object.values(this.tree.children)
        .flat()
        .filter(a => this.form.sourceIds.some(s => s._id === a._id))
        .map(a => ({
          label: `${a.name} (${a.meterPointNumber ? `${a.meterPointNumber}` : ''}${a.deviceId ? ` | ${a.deviceId}` : ''})`,
          value: a._id,
          assetId: a.assetId,
          type: a.type
        }));

      const assets = [...this.assets]
        .filter(a => this.form.sourceIds.some(s => s._id === a._id) || accounts.some(acc => acc.assetId === a._id))
        .map(a => ({
          label: `${a.siteName}${a.siteCode ? ` (${a.siteCode})` : ''}`,
          value: a._id,
          isSelected: this.form.sourceIds.some(s => s._id === a._id)
        }));

      return {
        assets,
        accounts
      };
    }
  },
  async mounted() {
    this.listUtilityTypes();
    this.listGranularities();
    this.getTags();

    await Promise.all([
      this.listAssets({ data: { params: { $limit: 100000, $select: 'siteName,siteCode', $multi: true } } }),
      this.listAssetGroups()
    ]);

    this.filteredAssets = this.assets;
    this.debouncedSearchFunction = debounce(this.performSearch, 500);

    this.assetGroupOptions = this.assetGroups.map(g => ({ label: g.name, value: g._id }));

    if (this.$route.query.template) {
      await Promise.all([this.listSystemTemplates(), this.listTemplates()]);
      const templates = [...this.templatesResults, ...this.systemTemplatesResults];

      this.template = templates.find(t => t._id === this.$route.query.template);

      Object.keys(this.form).forEach(key => {
        if (this.template[key] !== undefined) {
          if (key === 'startDate') {
            this.$set(this.dateRange, 0, this.template[key]);
          } else if (key === 'endDate') {
            this.$set(this.dateRange, 1, this.template[key]);
          } else {
            this.$set(this.form, key, this.template[key]);
          }
        }

        if (this.template.data[key] !== undefined) {
          if (key === 'startDate') {
            this.$set(this.dateRange, 0, this.template.data[key]);
          } else if (key === 'endDate') {
            this.$set(this.dateRange, 1, this.template.data[key]);
          } else {
            this.$set(this.form, key, this.template.data[key]);
          }
        }
      });

      // Fetch all accounts for an asset that is within the template, so we dont end up with partial account data for assets
      if ((this.template.data.sourceIds || []).filter(s => s.type === 'account').length > 0) {
        const accountAssetIds = this.template.data.sourceIds.filter(s => s.type === 'account').map(s => s.parentId);

        const relatedAccounts = await this.listAccounts({
          data: {
            params: {
              assetId: accountAssetIds.join(','),
              $limit: 100000,
              $select: accountSelectParams,
              $multi: true
            }
          }
        });

        this.tree.children = relatedAccounts.data.reduce((acc, curr) => {
          acc[curr.assetId] = [...(acc[curr.assetId] || []), curr];
          return acc;
        }, {});
      }

      this.onSelectSource(this.template.subType, this.template.data.sourceIds);
    } else {
      this.onSelectSource(this.form.subType, this.form.sourceIds);
    }

    // Set initial start/end dates from date range
    this.onSelectDateRange();

    chartGenerationOptions.forEach(option => {
      this.$set(this.form.chartOptions, option.name, option.default);
    });

    this.tree.loading = false;
  },
  methods: {
    ...mapActions({
      listUtilityTypes: 'util/listUtilityTypes',
      listAccounts: 'account/list',
      listAssets: 'asset/list',
      listAssetsFromGroups: 'assetGroup/listAssets',
      listAssetGroups: 'assetGroup/list',
      listGranularities: 'util/listGranularities',
      getAccountSchema: 'account/schema',
      getAssetSchema: 'asset/schema',
      getAssetGroupSchema: 'assetGroup/schema',
      getReadingSchema: 'reading/schema',
      getConsumptionSchema: 'consumption/schema',
      getInvoiceSchema: 'invoice/schema',
      getSupplierSchema: 'supplier/schema',
      generateReport: 'report/generateReport2',
      createTemplate: 'reportTemplate/create',
      getTemplate: 'reportTemplate/get',
      listTemplates: 'reportTemplate/list',
      listSystemTemplates: 'reportTemplate/listSystem',
      getTags: 'account/getTags',
      searchAccounts: 'account/search'
    }),
    onSelectDateRange() {
      const [startDate, endDate] = this.dateRange;

      this.form.startDate = startDate;
      this.form.endDate = endDate;
    },
    removeSearchTags() {
      Object.values(this.tree.children).forEach(children => {
        children.forEach(account => (account.search = false));
      });
    },
    onSearch() {
      // unexpand all assets
      this.tree.expanded = [];

      if (!this.tree.search.query) {
        // reset search if no search value
        this.debouncedSearchFunction.cancel();
        this.tree.search.loading = false;
        this.filteredAssets = this.assets;
        this.removeSearchTags();
        return;
      }

      if (this.tree.search.query.length < 3) return false;

      this.tree.search.loading = true;

      // sets off the `performSearch` method
      this.debouncedSearchFunction();
    },
    async performSearch() {
      // Get all assets that match the search query
      const searchQuery = this.tree.search.query.toLowerCase();

      const assetSearchResults = this.assets.filter(asset => {
        const search = `${asset.siteName} ${asset.addressString} ${asset.siteCode}`.toLowerCase();
        return search.includes(searchQuery);
      });

      const promises = [
        this.searchAccounts({
          data: {
            params: {
              query: searchQuery,
              $select: accountSelectParams,
              $limit: 100000
            }
          }
        })
      ];

      if (assetSearchResults.length > 0) {
        promises.push(
          this.listAccounts({
            data: {
              params: {
                assetId: assetSearchResults.map(a => a._id).join(','),
                $limit: 100000,
                $select: accountSelectParams,
                $multi: true
              }
            }
          })
        );
      }

      await Promise.all(promises);

      this.processSearchResults(assetSearchResults);
      this.tree.search.loading = false;
    },
    processSearchResults(assetSearchResults) {
      const { data: accountSearchResults } = this.accountSearchResults;
      if (!accountSearchResults.length && !assetSearchResults.length) {
        this.filteredAssets = [];
        return;
      }

      // Merge matched assets and accounts to get a unique list of assetIds
      const uniqueMatchedAssetIds = new Set([...assetSearchResults.map(asset => asset._id), ...accountSearchResults.map(account => account.assetId)]);
      this.filteredAssets = this.assets.filter(asset => uniqueMatchedAssetIds.has(asset._id));

      // if account search results are found, we need to expand the asset to show the account results &
      // tag the account as a search result so it will be displayed
      // untag search flag on all accounts
      this.removeSearchTags();
      // Add account search results to the tree
      accountSearchResults.forEach(account => {
        // expand asset to show account result
        if (!this.tree.expanded.includes(account.assetId)) this.tree.expanded.push(account.assetId);

        // Tag account as search result so it will displayed
        const accountWithSearch = { ...account, search: true };

        // Add account to tree
        if (!this.tree.children[account.assetId]) this.tree.children[account.assetId] = [];
        const accountIndex = this.tree.children[account.assetId].findIndex(acc => acc._id === account._id);

        if (accountIndex === -1) {
          this.tree.children[account.assetId].push(accountWithSearch);
        } else {
          this.tree.children[account.assetId][accountIndex] = accountWithSearch;
        }
      });

      // for all assets that are not expanded, tag the related accounts
      // so when the asset is expanded, the accounts will be displayed
      this.accounts
        .filter(account => !this.tree.expanded.includes(account.assetId))
        .forEach(account => {
          // Tag account as search result so it will displayed
          const accountWithSearch = { ...account, search: true };

          // Add account to tree
          if (!this.tree.children[account.assetId]) this.tree.children[account.assetId] = [];
          const accountIndex = this.tree.children[account.assetId].findIndex(acc => acc._id === account._id);

          if (accountIndex === -1) {
            this.tree.children[account.assetId].push(accountWithSearch);
          } else {
            this.tree.children[account.assetId][accountIndex] = accountWithSearch;
          }
        });
    },
    async onClickTreeItem(item) {
      if (item.type !== 'asset') return;

      const assetId = item.value;
      const isCurrentlyExpanded = this.tree.expanded.includes(assetId);

      if (isCurrentlyExpanded) {
        this.tree.expanded = this.tree.expanded.filter(i => i !== assetId);
        return;
      }

      // fetch children if not searching or no children
      const shouldFetchChildren = !this.tree.search.query;
      if (shouldFetchChildren) {
        await this.fetchAssetChildren(assetId);
      }

      this.tree.expanded.push(assetId);
    },
    async fetchAssetChildren(assetId) {
      this.tree.childrenLoading.push(assetId);

      const relatedAccounts = await this.listAccounts({
        data: {
          params: {
            assetId,
            $limit: 100000,
            $select: accountSelectParams,
            $multi: true
          }
        }
      });

      this.tree.children[assetId] = relatedAccounts.data;
      this.tree.childrenLoading = this.tree.childrenLoading.filter(id => id !== assetId);
    },
    async onSelectPortfolio(assetGroupId) {
      if (!assetGroupId) {
        this.form.sourceIds = [];
        this.selectedAssetGroup = null;
        return;
      }

      if (this.template.strictSource && this.template.source !== 'asset-group') {
        this.$toasted.error(`Only an ${this.template.source} can be selected for this report.`);
        return;
      }

      this.selectedAssetGroup = this.assetGroups.find(g => g._id === assetGroupId);

      const relatedAssets = await this.listAssetsFromGroups({
        id: this.selectedAssetGroup._id,
        data: { params: { $select: 'siteName,siteCode' } }
      });

      this.form.sourceIds = relatedAssets.map(acc => ({ _id: acc._id, type: 'asset', parentId: null }));
    },
    onSelectTreeItem(item) {
      if (this.form.sourceIds.some(s => s._id === item.value) || this.form.sourceIds.some(s => s._id === item.assetId)) {
        this.form.sourceIds = this.form.sourceIds.filter(s => s._id !== item.value);
      } else {
        if (!this.isDynamic && this.template.singleSource) {
          if (this.form.sourceIds.length > 0) {
            this.form.sourceIds = [];
          }

          if (this.template.strictSource && item.type !== this.template.source) {
            this.$toasted.error(`Only an ${this.template.source} can be selected for this report.`);
            return;
          }
        }

        this.form.sourceIds.push({ _id: item.value, type: item.type, parentId: item.assetId });
      }

      this.onSelectSource();
    },
    async onSelectSource(type, sourceIds = []) {
      if (sourceIds.length > 0) {
        this.form.sourceIds = sourceIds;
      }

      if (!this.isDynamic && !type) {
        this.form.reportType = this.template.data.type;
      }

      if (this.isDynamic) this.onSelectType();
    },
    async onSelectType() {
      this.schemaLoading = true;
      this.schemaItems = [];
      const schemasToFetch = [];

      // Determine what Schemas will be needed based on what the user selects

      if (this.form.source === 'asset') {
        schemasToFetch.push('assets');
      } else if (this.form.source === 'account') {
        schemasToFetch.push('accounts');
      } else if (this.form.source === 'assetGroup') {
        schemasToFetch.push('assetGroups');
      }

      if (this.form.source === 'assetGroup' && ['accounts', 'readings', 'consumptions', 'invoices'].includes(this.form.subType)) {
        schemasToFetch.push('assets');
        schemasToFetch.push('accounts');
      }

      if (this.form.source === 'asset' && ['readings', 'consumptions', 'invoices'].includes(this.form.subType)) {
        schemasToFetch.push('accounts');
      }

      if (this.form.source === 'asset' && ['accounts', 'invoices'].includes(this.form.subType)) {
        schemasToFetch.push('suppliers');
      }

      schemasToFetch.push(this.form.subType);

      if (schemasToFetch.includes('accounts')) {
        await this.getAccountSchema();
        const items = this.accountSchema.map(g => ({ label: `Account - ${g.friendly}`, value: `account-${g.value}` }));
        this.schemaItems.push(...items);
      }

      if (schemasToFetch.includes('assets')) {
        await this.getAssetSchema();
        const items = this.assetSchema.map(g => ({ label: `Asset - ${g.friendly}`, value: `asset-${g.value}` }));
        this.schemaItems.push(...items);
      }

      if (schemasToFetch.includes('assetGroups')) {
        await this.getAssetGroupSchema();
        const items = this.assetGroupSchema.map(g => ({ label: `Portfolio - ${g.friendly}`, value: `assetGroup-${g.value}` }));
        this.schemaItems.push(...items);
      }

      if (schemasToFetch.includes('readings')) {
        await this.getReadingSchema();
        const items = this.readingSchema.map(g => ({ label: `Reading - ${g.friendly}`, value: `reading-${g.value}` }));
        this.schemaItems.push(...items);
      }

      if (schemasToFetch.includes('consumptions')) {
        await this.getConsumptionSchema();
        const items = this.consumptionSchema.map(g => ({ label: `Consumption - ${g.friendly}`, value: `consumption-${g.value}` }));
        this.schemaItems.push(...items);
      }

      if (schemasToFetch.includes('invoices')) {
        await this.getInvoiceSchema();
        const items = this.invoiceSchema.map(g => ({ label: `Invoice - ${g.friendly}`, value: `invoice-${g.value}` }));
        this.schemaItems.push(...items);
        this.dateField = 'invoiceDate';
      }

      if (schemasToFetch.includes('suppliers')) {
        await this.getSupplierSchema();
        const items = (this.supplierSchema || []).map(g => ({ label: `Supplier - ${g.friendly}`, value: `supplier-${g.value}` }));
        this.schemaItems.push(...items);
      }

      // Ensure items with prefix same as "subType" are sorted first, with labels secondary

      const type = this.form.subType.substring(0, this.form.subType.length - 1);

      this.schemaItems.sort((a, b) => {
        if (a.value.startsWith(type) && !b.value.startsWith(type)) {
          return -1;
        }

        if (!a.value.startsWith(type) && b.value.startsWith(type)) {
          return 1;
        }

        return 0;
      });

      this.schemaLoading = false;
    },
    async onClickGenerate() {
      this.generating = true;

      // remove form elements that are hidden
      Object.keys(this.form).forEach(key => {
        if (Object.keys(this.formDisplayRules).includes(key) && !this.displayCheck[key]) {
          this.$set(this.form, key, null);
        }
      });

      const report = await this.generateReport({
        type: this.isDynamic ? 'dynamic' : 'system',
        data: {
          ...this.form,
          ...(this.$route.query.template ? { reportTemplateId: this.$route.query.template } : {})
        }
      });

      this.$mixpanel.track('Generate Report', {
        'Report Type': this.form.type,
        'Report Sub Type': this.form.subType,
        'Report Source': this.form.source,
        'Report File Format': this.form.fileFormat
      });

      if (report.fileFormat === 'chart') {
        this.$router.push({ name: 'reports-chart', params: { id: report._id } });
      } else {
        this.$router.push({ name: 'reports-download', params: { id: report._id } });
      }
    },
    async onCreateTemplate() {
      try {
        await this.createTemplate({
          data: {
            ...this.templateForm,
            data: this.form,
            type: this.isDynamic ? 'dynamic' : this.template.type,
            subType: this.isDynamic ? this.form.subType : this.template.subType,
            source: this.isDynamic ? this.form.source : this.template.source,
            fileFormat: this.form.fileFormat,
            singleSource: !!this.template?.singleSource,
            strictSource: !!this.template?.strictSource,
            beta: false,
            disabled: false
          }
        });

        this.templateModal = false;
        this.$toasted.success('Template created successfully');
      } catch (e) {
        this.$toasted.error('Something went wrong');
      }
    }
  }
};
</script>
<style scoped lang="scss">
.selected--partial {
  border-right: 6px solid #db8c41;
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.selected {
  border-right: 6px solid #65c198;
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.selected--portfolio {
  border-right: 6px solid #3c90df;
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.Tree {
  height: 800px;
  overflow-y: scroll;
  padding-right: 10px;

  &-item {
    cursor: pointer;
    transition: all 0.2s ease-in-out;
  }

  &-item.selected {
    background-color: #eff7f3 !important;
  }

  &-expander {
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }

  &-expander:hover {
    background: #f3f7ff !important;
  }

  &-label {
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }

  &-label:hover {
    background: #f3f7ff !important;
  }
}
</style>
